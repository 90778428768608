import {
  Box,
  Flex,
  Heading,
  //   Link,
  Text,
  // Button,
  Image,
  Icon,
  // List,
  // ListItem,
  // ListIcon,
  useColorModeValue,
} from '@chakra-ui/react'
import React from 'react'
import LandingImg from '../images/landingImg.jpg'
import { BiCheckShield } from '@react-icons/all-files/bi/BiCheckShield'
import { BiSupport } from '@react-icons/all-files/bi/BiSupport'
import { BiTime } from '@react-icons/all-files/bi/BiTime'
import { BiMoney } from '@react-icons/all-files/bi/BiMoney'
import { BiAlignLeft } from '@react-icons/all-files/bi/BiAlignLeft'
import { BiPhoneCall } from '@react-icons/all-files/bi/BiPhoneCall'
import { IoLogoWhatsapp } from '@react-icons/all-files/io/IoLogoWhatsapp'
import { HiOutlineMail } from '@react-icons/all-files/hi/HiOutlineMail'
import {
  ButtonWithIcon,
  fontSizes,
  Spacer,
} from '../components/UtilityComponents'

export const LandingSection = () => {
  const gradientColor = useColorModeValue(
    'linear(to-l, white, transparent)',
    'linear(to-l, gray.800, transparent)'
  )
  const fontColorHeading = useColorModeValue(
    'linear(to-tl, purple.500, blue.500)',
    'linear(to-tl, white, white)'
  )
  // const fontColorPreHeading = useColorModeValue(
  //   'cyan.500',
  //   'teal.300'
  // )
  const fontChooseUsColor = useColorModeValue(
    'brand.600',
    'purple.300'
  )
  const headingCardColor = useColorModeValue('cyan.500', 'cyan.200')
  // const descriptionCardColor = useColorModeValue(
  //   'gray.500',
  //   'gray.400'
  // )
  // const fontColorListItem = useColorModeValue(
  //   'brand.600',
  //   'brand.200'
  // )

  const ListItemRender = ({
    iconName,
    iconSize,
    iconColor,
    itemText,
  }) => {
    return (
      <Box
        // color={fontColorListItem}
        // fontSize={['sm', 'sm', 'md', 'lg']}
        display="flex"
        marginTop={'4'}
        flexDirection="row"
        alignItems="center"
        // justifyContent={['center', 'center', 'left', 'left']}
        // justifyContent={['left']}
        // borderColor="yellow.900"
        // borderWidth={1}
      >
        <Icon
          as={iconName}
          fontSize={['4xl', '5xl', '5xl', '5xl']}
          color={iconColor}
        />
        <Heading
          color={headingCardColor}
          fontSize={fontSizes.subHeading}
          marginY={2}
          fontWeight={['medium']}
          marginLeft={[4]}
        >
          {itemText}
        </Heading>
      </Box>
    )
  }

  return (
    <Box
      margin={'auto'}
      maxWidth={1330}
      marginTop={[5]}
      alignItems="center"
      justifyContent={['center']}
    >
      <Spacer />
      {/* <Text
        fontSize={['lg', 'xl', '2xl', '2xl']}
        m={2}
        textAlign={'center'}
        color={fontColorPreHeading}
      >
        Aishwarya Agarwal &amp; Co. (Chartered Accountants)
      </Text> */}
      <Heading
        letterSpacing="wide"
        fontWeight="extrabold"
        lineHeight={'normal'}
        margin="auto"
        marginX={[3]}
        marginY={[6]}
        marginTop={[10]}
        fontFamily="heading"
        bgClip={'text'}
        bgGradient={fontColorHeading}
        fontSize={fontSizes.landingHeading}
        textTransform="uppercase"
        borderColor="yellow.900"
        borderWidth={0}
        textAlign={['center']}
      >
        Your New Digital Business Advisor
      </Heading>

      <Flex
        flexDirection={['row']}
        justifyContent={['center']}
        alignItems={['center', 'center', 'center', 'center']}
        borderColor={[
          'gray.100',
          'red.300',
          'blue.300',
          'yellow.900',
        ]}
        borderWidth={[0, 0, 0, 0]}
        marginY={[10, 20, 15, 20]}
        marginX={[3]}
        marginBottom={[10]}
      >
        <Box
          position={'relative'}
          as="div"
          maxWidth={[null, null, '55%', '550px']}
          display={['none', 'none', 'block', 'block']}
          marginBottom={[5, 5, 0]}
          marginRight={[5, 5, 5, 10]}
        >
          <Image zIndex={10} src={LandingImg} />
          <Box
            // zIndex={100}
            position={'absolute'}
            width={[0, 0, '100%', '100%']}
            h={'100%'}
            top={0}
            borderColor="yellow.900"
            borderWidth={0}
            right={0}
            bgGradient={gradientColor}
          />
        </Box>
        <Box
          justifyContent={['center', 'center', 'left', 'left']}
          textAlign={['center', 'center', 'left', 'left']}
          borderColor="yellow.900"
          borderWidth={0}
          maxWidth={['92%', '90%', '55%', '600px']}
          // marginLeft={2}
        >
          <Text
            fontSize={['md', 'lg', 'lg', 'lg']}
            m={2}
            // textAlign={'center'}
            color={fontChooseUsColor}
          >
            Reasons to choose Aishwarya Agarwal &amp; Co.
          </Text>
          <Flex
            flexDirection={['column']}
            paddingX={['auto', 'auto', 0, 0]}
            borderColor="yellow.900"
            borderWidth={0}
            width={['fit-content', null, null, null]}
            marginX={['auto', 'auto', 0, 0]}
          >
            <ListItemRender
              iconName={BiCheckShield}
              iconSize={'2xl'}
              iconColor={'green.300'}
              itemText={'Client Confidentiality'}
            />
            <ListItemRender
              iconName={BiTime}
              iconSize={'2xl'}
              iconColor={'purple.300'}
              itemText={'Timely Services'}
            />
            <ListItemRender
              iconName={BiSupport}
              iconSize={'2xl'}
              iconColor={'pink.300'}
              itemText={'Customer Support'}
            />

            <ListItemRender
              iconName={BiMoney}
              iconSize={'2xl'}
              iconColor={'teal.300'}
              itemText={'Cost Effective'}
            />
            <ListItemRender
              iconName={BiAlignLeft}
              iconSize={'2xl'}
              iconColor={'orange.300'}
              itemText={'One Stop Solution'}
            />
          </Flex>
        </Box>
      </Flex>
      <Flex
        justifyContent={[
          'space-between',
          'space-between',
          'space-evenly',
          'space-evenly',
        ]}
        width={['80%', '90%', '50%', '50%']}
        // marginY={[5, 5, ,]}
        marginTop={[5, 10, 10, 10]}
        marginX={['auto']}
      >
        <ButtonWithIcon
          iconName={BiPhoneCall}
          bgColor={'green.400'}
          href={'tel:6281482842'}
        />
        <ButtonWithIcon
          iconName={IoLogoWhatsapp}
          bgColor={'whatsapp.500'}
          href={'https://wa.me/916281482842'}
        />
        <ButtonWithIcon
          iconName={HiOutlineMail}
          bgColor={'brand.400'}
          href={'mailto:ca.aishwaryatulsyan@gmail.com'}
        />
      </Flex>
      <Spacer />
    </Box>
  )
}
