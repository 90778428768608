import {} from '@chakra-ui/react'
// import { Link as GatsbyLink } from 'gatsby'
import React from 'react'

import SEO from '../components/seo'
import { LandingSection } from '../components/LandingSection'
import { AboutUs } from '../components/AboutUs'
import { Services } from '../components/Services'
// import { Insights } from '../components/Insights'
import { ContactUs } from '../components/ContactUs'
// import { ServicesContent } from '../data'
import { Spacer } from '../components/UtilityComponents'

const IndexPage = () => (
  <>
    <SEO title="Aishwarya Agarwal & Co." />
    <LandingSection />
    <AboutUs />
    <Spacer />
    <Services />
    <ContactUs />
  </>
)

export default IndexPage
