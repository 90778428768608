import React, { useState } from 'react'
import {
  Box,
  Flex,
  Heading,
  //   Link,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Icon,
  Button,
  Textarea,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
} from '@chakra-ui/react'
// import { fontColorHeading } from './LandingSection'
import { Spacer } from '../components/UtilityComponents'
import { IoLocationOutline } from '@react-icons/all-files/io5/IoLocationOutline'
import { HiOutlineMail } from '@react-icons/all-files/hi/HiOutlineMail'
import { BiPhoneCall } from '@react-icons/all-files/bi/BiPhoneCall'
import { IoLogoWhatsapp } from '@react-icons/all-files/io/IoLogoWhatsapp'

import {
  fontSizes,
  ButtonWithIcon,
} from '../components/UtilityComponents'
export const ContactUs = () => {
  const backgroundButtonColor = useColorModeValue(
    'linear(to-br, brand.200, brand.100)',
    'linear(to-br, brand.600, brand.500)'
  )
  const formBackgroundColor = useColorModeValue(
    'gray.50',
    'brand.700'
  )
  const fontLabelColor = useColorModeValue('brand.400', 'brand.300')
  // const sectionBackgroundColor = useColorModeValue(
  //   'linear(to-br, teal.100, cyan.100)',
  //   'linear(to-br, brand.900, brand.900)'
  // )
  // const fontColorListItem = useColorModeValue(
  //   'brand.600',
  //   'brand.200'
  // )
  function encode(data) {
    return Object.keys(data)
      .map(
        key =>
          encodeURIComponent(key) +
          '=' +
          encodeURIComponent(data[key])
      )
      .join('&')
  }
  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const [submitSuccessful, setSubmitSuccessful] = useState(false)
  const [submitFailed, setSubmitFailed] = useState(false)
  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    // console.log(state)
    fetch('/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => {
        setSubmitSuccessful(true)
        setSubmitFailed(false)
        setTimeout(() => {
          setSubmitSuccessful(false)
          setSubmitFailed(false)
        }, 3000)
        console.log('Form submitted successfully!')
      })
      .catch(error => {
        console.log('Form submit failed!')
        setSubmitSuccessful(false)
        setSubmitFailed(true)
        setTimeout(() => {
          setSubmitSuccessful(false)
          setSubmitFailed(false)
        }, 3000)
      })
  }

  return (
    <>
      <Spacer id="contact-us" />

      <Box
      // bgGradient={sectionBackgroundColor}
      >
        <Flex
          margin={'auto'}
          paddingY={[5, 5, 10, 10]}
          paddingBottom={[, , 5, 5]}
          paddingX={[5, 7, 10, 10]}
          maxWidth={1400}
          flexDirection={['column']}
          marginTop={[2]}
          borderColor="yellow.900"
          borderWidth={0}
        >
          <Heading
            letterSpacing="wide"
            fontWeight="bold"
            textTransform={'uppercase'}
            maxW={['600px']}
            fontFamily="heading"
            marginBottom={[5]}
            fontSize={fontSizes.heading}
          >
            Contact Us
          </Heading>
          <Flex
            flexDirection={['column', 'column', 'row', 'row']}
            justifyContent={[
              'center',
              'center',
              'space-between',
              'space-between',
            ]}
            alignItems={['center']}
            borderColor="yellow.900"
            borderWidth={0}
          >
            <Box width={['95%', '95%', '50%', '50%']}>
              <FormLabel
                marginTop={[0]}
                color={fontLabelColor}
                fontSize={['xs', 'sm', 'sm', 'md']}
                alignItems={['center']}
                display={['flex']}
              >
                <Icon
                  as={IoLocationOutline}
                  fontSize={['sm', 'md', 'lg', 'xl']}
                  marginRight={[2]}
                />{' '}
                <Text> Address</Text>
              </FormLabel>
              <Text
                // color={'brand.800'}
                fontSize={['sm', 'md', 'md', 'lg']}
              >
                Himayatnagar, 500029, Hyderabad.
              </Text>
              <Text
                fontSize={['sm', 'md', 'md', 'lg']}
                marginTop={[2]}
              >
                7-1-19, Dwarkanagar, 504001, Adilabad.
              </Text>
              <FormLabel
                marginTop={[10]}
                color={fontLabelColor}
                fontSize={['xs', 'sm', 'sm', 'md']}
                alignItems={['center']}
                display={['flex']}
              >
                <Icon
                  as={HiOutlineMail}
                  fontSize={['sm', 'md', 'lg', 'xl']}
                  marginRight={[2]}
                />{' '}
                <Text>Email</Text>
              </FormLabel>
              <Flex
                flexDirection={['column']}
                width={['fit-content']}
              >
                <Text
                  as={'a'}
                  rel={'noopener'}
                  href={'mailto:ca.aishwaryatulsyan@gmail.com'}
                  fontSize={['sm', 'md', 'md', 'lg']}
                  // marginTop={[2]}
                >
                  ca.aishwaryatulsyan@gmail.com
                </Text>
                {/* <Text
                  as={'a'}
                  rel={'noopener'}
                  href={'mailto:aishwaryaagrawal26@gmail.com'}
                  fontSize={['sm', 'md', 'md', 'lg']}
                  marginTop={[2]}
                >
                  aishwaryaagrawal26@gmail.com
                </Text> */}
              </Flex>
              <FormLabel
                marginTop={[10]}
                color={fontLabelColor}
                fontSize={['xs', 'sm', 'sm', 'md']}
                alignItems={['center']}
                display={['flex']}
              >
                <Icon
                  as={BiPhoneCall}
                  fontSize={['sm', 'md', 'lg', 'xl']}
                  marginRight={[2]}
                />

                <Text>Phone / Whatsapp</Text>
              </FormLabel>
              {/* <Text
                // color={'brand.800'}
                fontSize={['sm', 'md', 'md', 'lg']}
              >
                8790251351
              </Text> */}
              <Text
                // color={'brand.800'}
                as="a"
                href={'tel:6281482842'}
                fontSize={['sm', 'md', 'md', 'lg']}
                marginTop={[2]}
              >
                6281482842
              </Text>
            </Box>
            <Box
              width={['95%', '95%', '50%', '50%']}
              marginX={[5]}
              backgroundColor={formBackgroundColor}
              borderRadius={[5]}
              boxShadow={['lg']}
              padding={[5]}
              marginY={[10, 5]}
              fontSize={['sm', 'md', 'md', 'lg']}
            >
              <Heading
                letterSpacing="wide"
                // textAlign={['center', 'left']}
                // marginTop={'2em'}
                fontWeight="medium"
                textTransform={'capitalize'}
                // lineHeight="tall"
                maxW={['600px']}
                fontFamily="heading"
                // color={'brand.300'}
                fontSize={fontSizes.subHeading}
                marginBottom={[6]}
                textAlign={['center']}
              >
                Leave a message
              </Heading>
              <Alert
                status="success"
                display={submitSuccessful ? 'flex' : 'none'}
              >
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle>Success!</AlertTitle>
                  <AlertDescription display="block">
                    You successfully submitted the form! Our Team will
                    respond to you soon.
                  </AlertDescription>
                </Box>
              </Alert>
              <Alert
                status="error"
                display={submitFailed ? 'flex' : 'none'}
              >
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle>Failed!</AlertTitle>
                  <AlertDescription>
                    There was an error while submiting the form,
                    please try again.
                  </AlertDescription>
                </Box>
              </Alert>
              <form
                name="contact"
                method="post"
                data-netlify="true"
                netlify-honeypot="bot-field"
                action="_blank"
                onSubmit={e => handleSubmit(e)}
                netlify
                fontSize={['sm', 'md', 'md', 'lg']}
              >
                <input
                  type="hidden"
                  name="form-name"
                  value="contact"
                />
                <input
                  type="hidden"
                  name="bot-field"
                  onChange={e => handleChange(e)}
                />
                <Box>
                  <FormControl marginTop={[15]} isRequired>
                    <FormLabel fontSize={['md']}>Email</FormLabel>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="your@email.com"
                      size={['md']}
                      onChange={e => handleChange(e)}
                      //   onChange={event => setEmail(event.currentTarget.value)}
                    />
                  </FormControl>
                </Box>
                <Box marginBottom={[5]} marginTop={[17]}>
                  <FormControl>
                    <FormLabel fontSize={['md']}>Phone</FormLabel>
                    <Input
                      id="phone"
                      name="phone"
                      type="text"
                      placeholder="Your phone number"
                      size={['md']}
                      onChange={e => handleChange(e)}
                      //   onChange={event => setEmail(event.currentTarget.value)}
                    />
                  </FormControl>
                </Box>
                <Box marginBottom={[5]} marginTop={[17]}>
                  <FormControl isRequired>
                    <FormLabel fontSize={['md']}>Message</FormLabel>
                    <Textarea
                      id="message"
                      name="message"
                      type="text"
                      placeholder="Your message"
                      size={['md']}
                      onChange={e => handleChange(e)}
                      //   onChange={event => setEmail(event.currentTarget.value)}
                    />
                  </FormControl>
                </Box>
                <div data-netlify-captcha></div>
                <Text fontSize={['sm']} fontStyle={['italic']}>
                  We usually respond within 24 hours
                </Text>
                <Button
                  marginY={[15]}
                  letterSpacing={['wide']}
                  textTransform={'uppercase'}
                  fontWeight={'medium'}
                  type="submit"
                  width="full"
                  mt={4}
                  bgGradient={backgroundButtonColor}
                >
                  Submit
                </Button>
              </form>
            </Box>
          </Flex>
          <Flex
            justifyContent={[
              'space-between',
              'space-between',
              'space-evenly',
              'space-evenly',
            ]}
            width={['90%', '90%', '50%', '50%']}
            marginY={[5, 5, ,]}
            marginTop={[5, 10, 10, 10]}
            marginX={['auto']}
            borderColor={'white'}
            borderWidth={[0]}
          >
            <ButtonWithIcon
              iconName={BiPhoneCall}
              bgColor={'green.400'}
              href={'tel:6281482842'}
            />
            <ButtonWithIcon
              iconName={IoLogoWhatsapp}
              bgColor={'whatsapp.500'}
              href={'https://wa.me/916281482842'}
            />
            <ButtonWithIcon
              iconName={HiOutlineMail}
              bgColor={'brand.400'}
              href={'mailto:ca.aishwaryatulsyan@gmail.com'}
            />
          </Flex>
        </Flex>
      </Box>
    </>
  )
}
