import React, { useState } from 'react'
import {
  Box,
  Flex,
  Heading,
  //   Link,
  Text,
  // Grid,
  // Image,
  // List,
  // ListItem,
  // ListIcon,
  useColorModeValue,
} from '@chakra-ui/react'
// import { fontColorHeading } from './LandingSection'
import { fontSizes } from '../components/UtilityComponents'
import { Spacer } from '../components/UtilityComponents'
export const AboutUs = () => {
  const backgroundColor = useColorModeValue(
    'linear(to-br, brand.400, brand.500 )',
    'linear(to-tl, brand.600, brand.500)'
  )
  const fontColor = useColorModeValue('brand.50', 'brand.1')
  // const fontColorListItem = useColorModeValue(
  //   'brand.600',
  //   'brand.200'
  // )
  const NO_OF_LINES = 10
  const [noOfLines, setNoOfLines] = useState(NO_OF_LINES)
  const handleToggleNoOfLines = () => {
    if (noOfLines === NO_OF_LINES) {
      setNoOfLines(null)
    } else {
      setNoOfLines(NO_OF_LINES)
    }
  }

  return (
    <>
      <Spacer id="about-us" />

      <Box bgGradient={backgroundColor}>
        <Flex
          margin={['auto']}
          paddingY={[10]}
          paddingX={[3, 5, 7]}
          maxWidth={[1330]}
          flexDirection={['column']}
          marginTop={[10, 20]}
          // bgGradient={backgroundColor}
          color={fontColor}
          borderColor="yellow.900"
          borderWidth={0}
          borderRadius={[5]}
          // boxShadow={'lg'}
        >
          <Heading
            letterSpacing="wide"
            // textAlign={['center', 'left']}
            // marginTop={'2em'}
            fontWeight="bold"
            textTransform={'uppercase'}
            // lineHeight="tall"
            maxW={['600px']}
            fontFamily="heading"
            // color={'brand.900'}
            fontSize={fontSizes.heading}
          >
            About Us
          </Heading>
          <Text noOfLines={[noOfLines, null, null, null]}>
            <Text
              marginTop={[5]}
              // color={'brand.800'}
              fontSize={fontSizes.cardDescription}
              textAlign={[, , 'justify', 'justify']}
            >
              We are a team of Chartered Accountants and Company
              Secretaries, dedicated professionals possessing
              expertise across a range of business needs. The founder
              is CA Aishwarya Agarwal (C. A , B.Com, Certified
              Concurrent Auditor) a young dynamic and highly dedicated
              professional, having expertise in Corporate, Financial
              and Start-up Services. A value addition to the team is
              CA. Sanjay Kumar Tulsyan (C. A, B.Com, Certified
              Concurrent Auditor) who is a member of the Institute
              since 1997. He has professional excellence and
              experience of more than 20 years in this elite
              profession. He has advanced knowledge in Corporate,
              Taxation and Banking fields, besides he is expertise in
              Real Estate right from RERA to other matters. We are a
              dedicated team which provide one stop solutions under
              one roof, which saves time and make business compliances
              quick and hassle free. We abide by strong ethics,
              thereby adding value to our client business. We ensure
              the delivery of personalised quality services to
              maintain elevated professional and ethical calibre. We
              listen, understand and provide a reliable solution.
            </Text>

            <Heading
              letterSpacing="wide"
              // textAlign={['center', 'left']}
              // marginTop={'2em'}
              fontWeight="medium"
              textTransform={'uppercase'}
              // lineHeight="tall"
              maxW={['600px']}
              fontFamily="heading"
              // color={'brand.900'}
              fontSize={fontSizes.subHeading}
              marginTop={[10]}
            >
              Vision &amp; Mission
            </Heading>
            <Text
              // display={noOfLines === null ? 'block' : 'none'}
              marginTop={[5]}
              // color={'brand.800'}
              fontSize={fontSizes.cardDescription}
              textAlign={[, , 'justify', 'justify']}
            >
              Our mission is to render professional services of the
              highest standard to clients, upholding the code of
              conduct and ethics of the profession. Our experienced
              professionals oversee all affairs in order to have a
              clear comprehension of our customer’s needs, based on
              which a consistency of quality services is assured. We
              emphasise on maximum communication to ensure clear-cut
              apprehension of issues and areas of concern. Therefore,
              we stress on strong communication with the client and
              consider their feedback extremely valuable. Our top most
              priority is to keep your information secure. All the
              information related to any client is considered
              confidential and will never be disclosed to anyone. We
              provide the most personalised services and appropriate
              advice to all the queries of a client. We provide
              Qualitative Professional services with utmost
              professionalism. Services will be offered Timely &amp;
              are Cost Effective.
            </Text>
          </Text>
          <Text
            display={['block', 'none', 'none', 'none']}
            onClick={() => handleToggleNoOfLines()}
            fontSize={['sm']}
            fontWeight={['normal']}
            cursor={'pointer'}
            alignSelf={'flex-start'}
            width={'fit-content'}
            marginTop={[3]}
            padding={[0]}
            borderRadius={[30]}
            backgroundColor={'transparent'}
            textDecor={'underline'}
            color={'#fff'}
          >
            {noOfLines === NO_OF_LINES ? 'Read More' : 'Read Less'}
          </Text>
        </Flex>
      </Box>
    </>
  )
}
