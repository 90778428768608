import React from 'react'
import {
  Box,
  Flex,
  Heading,
  Link,
  Text,
  // Grid,
  // Image,
  // List,
  // Button,
  // ListItem,
  // ListIcon,
  useColorModeValue,
} from '@chakra-ui/react'
// import { fontColorHeading } from './LandingSection'
import {
  ServicesCard,
  fontSizes,
} from '../components/UtilityComponents'
// import { Link } from 'gatsby'
import { ServicesContent } from '../data'
export const Services = ({}) => {
  const fontColorHeading = useColorModeValue('brand.900', 'brand.1')
  const backgroundMoreServicesColor = useColorModeValue(
    'linear(to-tl, brand.100, cyan.100)',
    'linear(to-br, brand.700, cyan.800)'
  )
  // const headingCardColor = useColorModeValue('brand.700', 'cyan.200')

  // const descriptionCardColor = useColorModeValue(
  //   'gray.500',
  //   'gray.400'
  // )
  const fontColor = useColorModeValue('brand.900', 'brand.1')
  // const fontColorSubHeading = useColorModeValue(
  //   'brand.700',
  //   'brand.100'
  // )
  // const fontColorListItem = useColorModeValue(
  //   'brand.600',
  //   'brand.200'
  // )
  return (
    <Box>
      <Flex
        margin={'auto'}
        paddingY={[10]}
        paddingX={[3, 5, 10]}
        maxWidth="1400px"
        flexDirection={['column']}
        marginTop={[5]}
        // justifyContent={['space-between', 'space-evenly']}
        // alignItems={['center', 'center', 'center', 'center']}
        borderColor="yellow.900"
        borderWidth={0}
      >
        <Heading
          letterSpacing="wide"
          // textAlign={['center', 'left']}
          marginBottom={[5]}
          fontWeight="bold"
          // lineHeight="tall"
          textTransform={'uppercase'}
          maxW={['600px']}
          fontFamily="heading"
          color={fontColorHeading}
          fontSize={fontSizes.heading}
        >
          Services
        </Heading>

        <Flex
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {ServicesContent.filter((item, index) => index < 6).map(
            (service, index) => (
              <ServicesCard
                index={index + 1}
                // id={'services-' + index}
                key={index}
                fromIndexPage={true}
                name={service.name}
                description={service.description}
                types={service.types}
                imageName={service.imageName}
              />
            )
          )}
        </Flex>
        <Link
          width={['100%']}
          href={
            '/services/#' +
            ServicesContent.filter((item, index) => index === 6)[0]
              .name.split(' ')
              .join('-')
          }
          textAlign={'center'}
          bgGradient={backgroundMoreServicesColor}
          color={fontColor}
          marginY={[4]}
          paddingY={[5]}
          borderRadius={[5]}
          boxShadow={'lg'}
          textDecoration={'none'}
          _hover={{ textDecoration: 'none' }}
        >
          <Text display="block" textDecoration={'none'}>
            {' '}
            More Services
          </Text>
        </Link>
      </Flex>
    </Box>
  )
}
